/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import "aos/dist/aos.css";
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "theme/variables";

body {
  background: #000000;
  color: #fff;
  font-family: "DM Sans", sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}
*:hover {
  transition: all 0.2s;
}
a,
button {
  cursor: pointer;
}
a:hover {
  color: #81edba;
}
.hidden {
  visibility: hidden;
  position: absolute;
}
.active {
  visibility: visible;
}
.error-message {
  color: red;
}

@font-face {
  font-family: HK;
  src: url("./assets/fonts/HK-Guise-Regular.otf");
}
:root {
  img {
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    border-style: none;
  }
  --size_xs: 576px;
  --size_s: 768px;
  --size_m: 992px;
  --size_l: 1200px;
  --dashboard-light-green: #88ffc7;
  --dashboard-red: #ff6f6f;

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  .responsive-main-container {
    margin: auto;
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1300px;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.x-bit-crazy-background {
  background: var(--x-bit-crazy-background-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

input {
  border-radius: 8px;
  border: 1px var(--x-bg-darkgray) solid;
  // color: var(--x-text) !important;
  padding: 20px;
  max-height: 50px;
  // --padding-end: 5px !important;

  background: var(--x-bg-gray);

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--x-text-gray-placeholder);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--x-text-gray-placeholder);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--x-text-gray-placeholder);
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -868px 0;
  }
  100% {
    background-position: 868px 0;
  }
}

.loading-line {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    var(--x-bg-gray) 8%,
    var(--x-bg-lightgray) 18%,
    var(--x-bg-gray) 33%
  );
  background-size: 1800px 104px;
  position: relative;
}

.wrapper {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}
.flex-column {
  flex-direction: column;
}
.flex-item-5 {
  flex: 5%;
}
.flex-item-8 {
  flex: 8%;
}

.flex-item-10 {
  flex: 10%;
}
.flex-item-12 {
  flex: 12%;
}
.flex-item-13 {
  flex: 13%;
}
.flex-item-15 {
  flex: 15%;
}
.flex-item-19 {
  flex: 19%;
}
.flex-item-20 {
  flex: 20%;
}
.flex-item-25 {
  flex: 25%;
}
.flex-item-30 {
  flex: 30%;
}
.flex-item-35 {
  flex: 35%;
}

.flex-item-40 {
  flex: 40%;
}

.flex-item-45 {
  flex: 45%;
}
.flex-item-50 {
  flex: 50%;
}
.flex-item-55 {
  flex: 55%;
}
.flex-item-60 {
  flex: 60%;
}
.flex-item-70 {
  flex: 70%;
}
.flex-item-75 {
  flex: 75%;
}
.flex-item-100 {
  flex: 100%;
}
.swiper {
  position: static;
}
.tooltip-multiline {
  white-space: pre-line;
}

button:hover {
  transition: all 0.3s;
}

.marine-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  background: var(--grad-2, linear-gradient(152deg, #81edb9 0%, #c2f0ed 100%));
  height: 36px;
  color: #082618;
  text-align: center;
  font-family: var(--font-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

.marine-btn:hover {
  filter: brightness(1.2);
  border: 0.3px solid var(--ion-color-primary);
}
.trades-count-tooltip {
  &-title {
    color: #fff;
    font-family: var(--font-primary);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
  &-text {
    margin-top: 10px;
    color: #ccc;
    font-family: var(--font-primary);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    .success-color {
      color: $success;
      font-weight: 600;
    }
    .white-color {
      font-weight: 600;
      color: #fff;
    }
  }
}

.disable-click {
  pointer-events: none;
}
